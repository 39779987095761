<template>
  <div class="annex">
    <el-row style="margin-bottom: 15px">
      <div
        style="
          background-color: #e8fff6;
          color: black;
          font-weight: 900;
          font-size: 20px;
          padding: 10px;
        "
      >
        人工成本
      </div>
      <el-card>
        <el-table :data="madeData" max-height="300" border style="width: 100%">
          <el-table-column type="index" align="center" label="序号" width="80"> </el-table-column>
          <el-table-column prop="userName" align="center" label="用户名"> </el-table-column>
          <el-table-column prop="sumhour" align="center" label="总工时"> </el-table-column>
          <el-table-column prop="staffProductionManhourCost" align="center" label="工时成本">
            <template slot-scope="scope">
              {{ scope.row.staffProductionManhourCost | thousands }}
            </template>
          </el-table-column>
        </el-table>
        <div style="border: 1px solid #ebeef5; height: 45px; margin-bottom: 5px">
          <div style="line-height: 44px; margin-left: 81%; font-size: 14px">
            合计：
            {{ staffProductionCostDis | thousands }} 元
          </div>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChangeHandler"
          :current-page="madeParameter.pageNow"
          :page-size="madeParameter.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="->,total, prev, pager, next, slot, jumper"
          :total="madeParameter.total"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
      </el-card>
    </el-row>
    <el-row style="margin-bottom: 15px">
      <div
        style="
          background-color: #e8ffe8;
          color: black;
          font-weight: 900;
          font-size: 20px;
          padding: 10px;
        "
      >
        项目报销信息
      </div>
      <el-card>
        <el-table
          :summary-method="getSummariesFn"
          show-summary
          :data="ProjectData"
          border
          style="width: 100%"
        >
          <el-table-column align="center" type="index" label="序号" width="80"> </el-table-column>
          <el-table-column width="200" align="center" prop="createName" label="提交人">
          </el-table-column>
          <el-table-column
            align="center"
            prop="contentDetail"
            label="事由附加详细说明（含参与人员）"
          >
            <template slot-scope="scope">
              <el-input
                disabled
                size="small"
                type="textarea"
                autosize
                v-model="scope.row.contentDetail"
              ></el-input
            ></template>
          </el-table-column>
          <el-table-column width="140" align="center" prop="taskDate" label="时间">
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column width="170" prop="cost" align="center" label="报销金额(元)">
            <template slot-scope="scope">
              {{ scope.row.cost | thousands }}
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="handleSizeChangeFn"
          @current-change="pageChangeHandlerFn"
          :current-page="ProjectParameter.pageNow"
          :page-size="ProjectParameter.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="->,total, prev, pager, next, slot, jumper"
          :total="ProjectParameter.total"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
      </el-card>
    </el-row>
    <el-row style="margin-bottom: 15px">
      <div
        style="
          background-color: #fbfbea;
          color: black;
          font-weight: 900;
          font-size: 20px;
          padding: 10px;
        "
      >
        外协成本
      </div>
      <el-card>
        <el-table
          :data="projectInformation.projectOutsourceList"
          border
          :summary-method="getSummaries"
          show-summary
          style="width: 100%"
        >
          <el-table-column align="center" type="index" label="序号" width="80"> </el-table-column>
          <el-table-column width="400" prop="outsourceName" label="外协公司">
            <template slot-scope="scope">
              <el-input disabled v-model="scope.row.outsourceName" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="约定内容">
            <template slot-scope="scope">
              <el-input
                disabled
                size="small"
                type="textarea"
                autosize
                v-model="scope.row.content"
              ></el-input
            ></template>
          </el-table-column>
          <el-table-column width="200" prop="outsourceCost" label="外协费用(元)">
            <template slot-scope="scope">
              {{ scope.row.outsourceCost | thousands }}
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { getInputValue, delcommafy, numberToCurrencyNo } from '@/util/jsencrypt'
export default {
  components: {},
  props: {
    projectDisRuleOptions: {
      type: Object,
      default: function () {
        return {}
      },
    },
    achievementsOptions: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      madeParameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        projectId: '',
      },
      madeData: [],
      ProjectParameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        globalstatusList: [60, 70],
        projectId: '',
      },
      ProjectData: [],
      pageSize: 0,
      staffProductionCostDis: 0,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      projectInformation: state => state.project.projectInformation,
      ProjectDisRuleOptions: state => state.project.ProjectDisRuleOptions,
    }),
  },
  filters: {
    thousands(a) {
      if (a == null || a == '' || a == undefined) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },

    // dateFormat(time3) {
    //   //将时间戳格式转换成年月日时分秒
    //   var date = new Date(time3);
    //   var Y = date.getFullYear() + "-";
    //   var M =
    //     (date.getMonth() + 1 < 10
    //       ? "0" + (date.getMonth() + 1)
    //       : date.getMonth() + 1) + "-";
    //   var D =
    //     (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";

    //   var h =
    //     (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    //   var m =
    //     (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    //     ":";
    //   var s =
    //     date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    //   var strDate = Y + M + D + h + m + s;
    //   return strDate;
    // },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.madeParameter.pageSize = this.userInfo.pageSizeLog
  },
  watch: {
    projectDisRuleOptions: {
      deep: true,
      immediate: true,
      handler: function (projectDisRuleOptions) {
        if (projectDisRuleOptions.projectPerformanceDisDataResponse != null) {
          this.staffProductionCostDis = delcommafy(
            projectDisRuleOptions.projectPerformanceDisDataResponse.staffProductionCostDis
          )
        }
      },
    },
    ProjectDisRuleOptions: {
      deep: true,
      immediate: true,
      handler: function (ProjectDisRuleOptions) {
        if (ProjectDisRuleOptions.projectPerformanceDisDataResponse) {
          this.staffProductionCostDis = delcommafy(
            this.ProjectDisRuleOptions.projectPerformanceDisDataResponse.staffProductionCostDis
          )
        }
      },
    },
    achievementsOptions: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal.id) {
          this.madeFn()
        }
      },
    },
  },

  methods: {
    getMade(param) {
      const { columns, data } = param
      const arr = data.deepClone()
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0 || index === 1 || index === 2) {
          sums[index] = ''
          return
        }

        arr.forEach(v => {
          v.outsourceCost = String(v.outsourceCost).replace(/,/g, '')
        })

        const values = arr.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            let sum = Number(Number(prev) + Number(curr)).toFixed(2) //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return sum
            } else {
              return prev
            }
          }, 0)
          sums[index] = Number(sums[index]).toLocaleString()
          sums[index] = '合计: ' + sums[index]
        } else {
          sums[index]
        }
      })
      return sums
    },
    getSummariesFn(param) {
      const { columns, data } = param
      // const arr = data.deepClone();
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0 || index === 1 || index === 2 || index === 3) {
          sums[index] = ''
          return
        }

        data.forEach(v => {
          v.outsourceCost = String(v.outsourceCost).replace(/,/g, '')
        })

        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            // let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = Number(sums[index]).toLocaleString()
          sums[index] = '合计: ' + sums[index] + '元'
        } else {
          sums[index]
        }
      })
      return sums
    },
    getSummaries(param) {
      const { columns, data } = param
      // const arr = data.deepClone();
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0 || index === 1 || index === 2) {
          sums[index] = ''
          return
        }

        data.forEach(v => {
          v.outsourceCost = String(v.outsourceCost).replace(/,/g, '')
        })

        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            // let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = Number(sums[index]).toLocaleString()
          sums[index] = '合计: ' + sums[index] + '元'
        } else {
          sums[index]
        }
      })
      return sums
    },
    pageChangeHandlerFn(val) {
      this.ProjectParameter.pageNow = val
      this.madeFn()
    },
    handleSizeChangeFn(val) {
      this.ProjectParameter.pageSize = val
      this.ProjectParameter.pageNow = 1
      this.madeFn()
    },
    pageChangeHandler(val) {
      this.madeParameter.pageNow = val
      this.madeFn()
    },
    handleSizeChange(val) {
      this.madeParameter.pageSize = val
      this.madeParameter.pageNow = 1
      this.madeFn()
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      // this.loading = true;
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          // this.loading = false;
          this.madeParameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.madeFn()
        })
        .catch(err => {
          console.log(err)
          // this.loading = false;
        })
    },
    madeFn() {
      this.madeParameter.projectId = this.achievementsOptions.id
      this.$api.project
        .projectRuleGetManhourCostList(this.madeParameter)
        .then(res => {
          this.loading = false

          this.madeData = res.data.records
          this.madeParameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      this.ProjectParameter.projectId = this.achievementsOptions.id
      this.$api.project
        .selectProjectExpenseDetailList(this.ProjectParameter)
        .then(res => {
          this.loading = false
          this.ProjectData = res.data.records
          this.ProjectParameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
  beforeDestroy() {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  /deep/.el-card {
    //width:50%;
    .annex-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    + .el-card {
      margin-top: 20px;
    }
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-table {
      height: auto;
    }
  }
}
</style>
